
export default {
  name: 'BaseUserVerified',
  props: {
    size: {
      type: String,
      default: 'default',
    },
    accounting: {
      type: Object,
      default: null,
    },
    roles: {
      type: Array,
      default: null,
    },
    verified: {
      type: Boolean,
      default: false,
    },
    isClient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isUserModerator() {
      return (
        this.$auth.loggedIn &&
        !!this.$auth.user.roles.some((role) =>
          ['ADMIN', 'MODERATOR', 'ACCOUNTER'].includes(role)
        )
      )
    },
    isModerator() {
      return !!this.roles?.find((role) => role === 'MODERATOR')
    },
  },
}
