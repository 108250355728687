
import mixin from './mixin.vue'
export default {
  name: 'BaseSubscriptionButton',
  mixins: [mixin],
  props: {
    size: {
      type: String,
      default: 'default',
    },
    text: {
      type: String,
      default: null,
    },
    styles: {
      type: String,
      default: 'default',
    },
  },
}
