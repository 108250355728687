
export default {
  name: 'MegasredaContentPostHeaderInfo',
  props: {
    post: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    telegram: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    auditorium() {
      if (this.post.author?.statistics?.auditorium < 100) {
        return 'менее 100'
      } else {
        return this.post.author?.statistics?.auditorium
      }
    },
  },
}
