import { render, staticRenderFns } from "./Info.vue?vue&type=template&id=533ea72c&"
import script from "./Info.vue?vue&type=script&lang=js&"
export * from "./Info.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseUserAvatar: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/User/Avatar.vue').default,BaseUserVerified: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/User/Verified.vue').default,BaseSubscriptionButton: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Base/Subscription/Button.vue').default,MegasredaContentPostHeaderUserMenu: require('/home/webuser/www/megasreda/frontend-v2/releases/20240313112438/components/Megasreda/Content/Post/Header/UserMenu.vue').default})
